!((document, Drupal, $) => {
  'use strict';

  Drupal.behaviors.facetIcons = {
    attach(context) {

      const searchFacets = document.getElementById('facetapi-facet-apachesolrsolr-block-bundle', context); // eslint-disable-line max-len

      if (!searchFacets) {
        return;
      }

      const facetLinks = searchFacets.querySelectorAll('.leaf a');

      for (const el of facetLinks ) {
        // Loop thru and assign class based on string.
        const str = el.innerHTML;

        switch(true) {
          case /Lesson/.test(str):
            el.closest('li').classList.add('filter-lesson');
            // self publish lesson also included.
            break;
          case /Blog/.test(str):
            el.closest('li').classList.add('filter-blog');
            break;
          case /Organization/.test(str):
            el.closest('li').classList.add('filter-org');
            break;
          case /Podcast/.test(str):
            el.closest('li').classList.add('filter-podcast');
            break;
          case /Books/.test(str):
            el.closest('li').classList.add('filter-books');
            break;
          case /CALI Page/.test(str):
            el.closest('li').classList.add('filter-page');
            break;
          case /FAQ/.test(str):
            el.closest('li').classList.add('filter-faq');
            break;
          case /Story/.test(str):
            el.closest('li').classList.add('filter-story');
            break;
          case /Crossword/.test(str):
            el.closest('li').classList.add('filter-crossword');
            break;
          default:
            // no default
        }
      }
    }
  };

  /**
  * Setup and attach the Main Navigation behaviors.
  *
  * @type {Drupal~behavior}
  */
  Drupal.behaviors.searchBlock = {
    attach(context) {
      const $searchBlockModal = $('.page-search-site .page--sidebar', context);

      if (!$searchBlockModal) {
        return;
      }
      this.setupMobileFacetDisplay($searchBlockModal);
    },

    setupMobileFacetDisplay: function ($facets) {
      var self = this;

      // Wrap the facets in our container.
      $facets.wrapInner('<div class="search__facets-container"><div class="search__facets"></div></div>'); // eslint-disable-line max-len

      // Wrap the container for control elements.
      var $facetContainer = $facets.find('.search__facets-container');

      // Define out buttons
      var $openButton = $('<button class="search__open-facets-menu" tabindex="1">' + Drupal.t('Filter Results') + '</button>'); // eslint-disable-line max-len
      var $closeButton = $('<button class="search__close-facets-menu">' + Drupal.t('Close') + '</button>'); // eslint-disable-line max-len

      // Setup our handlers.
      $openButton
        .on({
          'keyup': function (event) {
            if (event.keyCode === 13) {
              self.open($facetContainer);
            }
          },
          'click': function () {
            self.open($facetContainer);
          }
        });

      $closeButton
        .on({
          'keyup': function (event) {
            if (event.keyCode === 13) {
              self.close($facetContainer);
            }
          },
          'click': function () {
            self.close($facetContainer);
          }
        });

      // Allow us to show/hide the close button when the facet panel is opened.
      // This adds an 'animation-end' class to the facets container after the
      // animation finishes.
      var $searchFacet = $('.search__facets', $facets);
      $searchFacet.on('transitionend', function () {
        if ($facetContainer.hasClass('open')) {
          $facetContainer.addClass('animation-end');
        }
        else {
          $facetContainer.removeClass('animation-end');
        }
      });

      // Insert our buttons to the container.
      $facetContainer.prepend($openButton);
      $facetContainer
        .find('.search__facets')
        .before($closeButton);
    },

    open: function ($facets) {
      $facets.addClass('open');
      $facets
        .find('facetapi-facet-im-field-cali-topics').eq(0)
        .trigger('focus');
    },

    close: function ($facets) {
      $facets.removeClass('open');
      $facets
        .find(':focus')
        .trigger('blur');
    }
  };
})(document, Drupal, jQuery);
